.home {
  width: 100%;
  height: 90vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.home-content {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upper-content {
  display: flex;
  flex-direction: row;
}

.left-side {
  flex: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button {
  color: white;
  width: 100px;
  text-decoration: none;
  padding: 1rem 2.5rem;
  background-color: black;
  outline: none;
  border: 1px solid white;
  border-radius: 3.5rem;
  font-size: 1.1rem;
  text-align: center;
  font-weight: 600;
}

.button:hover {
  background-color: red;
}

.right-side {
  flex: 50%;
}

.lower-content {
}

.contract {
  color: white;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 600;
}

.image {
  width: 60%;
}

.title {
  color: white;
  font-size: 2.5rem;
  text-align: center;
  font-weight: 600;
}

.subtitle {
  color: white;
  font-size: 2rem;
  text-align: center;
  font-weight: 600;
}
