.footer {
  width: 100%;
  height: 5rem;
  background-color: #121619;
  display: flex;
  flex-direction: row;
}

.left-side {
  flex: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 6rem;
}

.right-side {
  flex: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10rem;
}

.social-link {
  color: white;
  text-decoration: none;
  margin-right: 20px;
}

.social-link:hover {
  background-color: transparent;
}

.left-side p {
  color: white;
  font-size: 1rem;
}
