.navbar {
  width: 100%;
  height: 5rem;
  background-color: #121619;
  display: flex;
  flex-direction: row;
}

.leftSide {
  flex: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 6rem;
}

.leftSide img {
  width: 7rem;
}

.rightSide {
  flex: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 6rem;
}

.navButton {
  color: white;
  text-decoration: none;
  padding: 1rem 2.5rem;
  background-color: red;
  outline: none;
  border: 1px solid white;
  border-radius: 3.5rem;
  font-size: 1.1rem;
  text-align: center;
  font-weight: 600;
}

.navButton:hover {
  background-color: black;
}
